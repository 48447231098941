import React from 'react'
import getVideoId from 'get-video-id'
import ReactPlayer from 'react-player/lazy'
// import styles from './figure.module.css'
// https://github.com/cookpete/react-player
function Vimeo (props) {
  // console.log({props})
  if (!props || !props.url) {
    return <div>Missing Vimeo URL</div>
  }
  const id = getVideoId(props.url).id
  const url = `https://player.vimeo.com/video/${id}`
  if (!id) {
    return <div>Missing Viemo URL</div>
  }
  return (
    <div style={{ position: 'relative', paddingTop: '56.25%' }}>
    <ReactPlayer
      style={{ position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}
      url={url}
      muted={true}
      loop={true}
      playing={true}
      width='100%'
      height='100%'
      controls={true}
    />
  </div>
  )
}
export default Vimeo
      // <iframe
      //   title='Vimeo Preview'
      //   style={{ position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }}
      //   src={url}
      //   frameBorder='0'
      //   allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      // />