import React from "react";

import { Box, SimpleGrid } from "@chakra-ui/react";
import { FlexibleContent } from "./FlexibleContent";
import imageUrlBuilder from "@sanity/image-url";
import background from "../../images/PremierFlexStripe.png";

const config = {
  dataset: process.env.GATSBY_SANITY_DATASET,
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  useCdn: process.env.NODE_ENV === "production",
  token: process.env.SANITY_READ_TOKEN,
};
const builder = imageUrlBuilder(config);

export function FlexibleLayout({ layout }) {
  // console.log({ layout })

  const LayoutManager = ({ layout }) => {
    const { innerSpacing, outerSpacing, fullWidth } =
      layout?.flexibleLayoutOptions || {};
    const { colorTheme, layoutBleed, bgImage, bottomBorder } =
      layout?.flexibleLayoutOptions?.theme || {};

    const layoutColCount = (layout) => {
      // console.log({ layout })
      let layoutCount = 0;
      if (layout?.flexibleLayoutColOneContent?.length > 0) {
        ++layoutCount;
      }
      if (layout?.flexibleLayoutColTwoContent?.length > 0) {
        ++layoutCount;
      }
      if (layout?.flexibleLayoutColThreeContent?.length > 0) {
        ++layoutCount;
      }
      if (layout?.flexibleLayoutColFoursContent?.length > 0) {
        ++layoutCount;
      }
      return layoutCount;
    };
    // const bgWidth = (layoutBleed) => {
    //   if (layoutBleed) {
    //     return '200vw'
    //   } else {
    //     return '100%'
    //   }
    // }
    // const bgLeft = (layoutBleed) => {
    //   if (layoutBleed) {
    //     return '-100vw'
    //   } else {
    //     return 'unset'
    //   }
    // }
    const bgColor = (colorTheme) => {
      if (colorTheme === "alternate") {
        return "#929497";
      }
      if (colorTheme === "dark") {
        return "#111";
      } else {
        return "transparent";
      }
    };
    const fontColor = (colorTheme) => {
      if (colorTheme === "alternate") {
        return "#fff";
      }
      if (colorTheme === "dark") {
        return "#fff";
      } else {
        return "initial";
      }
    };
    // const layoutBorder = (val) => val === true ? '1px solid #dedede' : 'none'
    const layoutSpacing = (val) => {
      if (val === "none") {
        return "0";
      } else if (val === "less") {
        return "1rem";
      } else if (val === "more") {
        return "6rem";
      } else {
        return "3rem";
      }
    };
    // const isFullWidth = (val) => {
    //   return val === true ? "100vw" : "1366px";
    // };
    const layoutBgImage = (val) => {
      return val
        ? `url(${builder.image(val).auto("format").width(1440).url()})`
        : null;
    };
    const beforeContent = (bgImage) => (bgImage ? '""' : null);
    const beforeOverlay = (colorTheme) => {
      if (colorTheme === "alternate") {
        return "rgba(146, 148, 151, .5)";
      } else if (colorTheme === "dark") {
        return "rgba(0, 0, 0, 0.30)";
      } else {
        return "rgba(255, 255, 255, 0.5)";
      }
    };

    const BottomBorder = ({ bottomBorder }) => {
      if (bottomBorder === "stripeFade") {
        return (
          <Box
            backgroundImage={`linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(${background})`}
            bgRepeat="repeat-x"
            bgPosition="bottom center"
            height="40px"
            position="relative"
            marginLeft="calc(50% - 50vw)"
            marginRight="calc(50% - 50vw)"
          />
        );
      } else {
        return null;
      }
    };

    return (
      <Box
        width="100%"
        // width={fullWidth ? `100vw` : `100%`}
        // backgroundColor={layoutBleed && bgColor(bgColor)}

        backgroundColor={layoutBleed ? bgColor(colorTheme) : 'transparent'}
        backgroundImage={(layoutBleed === true && fullWidth === true && layoutBgImage) ? layoutBgImage(bgImage) : 'none'}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        position="relative"
        _before={
          layoutBleed && {
            content: beforeContent(bgImage),
            position: "absolute",
            backgroundColor: beforeOverlay(colorTheme),
            top: 0,
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: 0,
          }
        }
        // _before={
        //   layoutBleed && {
        //     content: beforeContent(bgImage),
        //     position: "absolute",
        //     backgroundColor: beforeOverlay(colorTheme),
        //     top: 0,
        //     left: "0",
        //     width: "100%",
        //     height: "100%",
        //     zIndex: -1,
        //   }
        // }
      >
        <SimpleGrid
          spacingX={{ base: "0", lg: "30px", xl: "60px" }}
          spacingY={{ base: "0", lg: "30px", xl: "60px" }}
          columns={{
            base: 1,
            md: `${layoutColCount(layout) >= 2 ? 2 : 1}`,
            lg: layoutColCount(layout),
          }}
          // width={fullWidth ? `100vw` : `100%`}
          alignItems="flex-start"
          // maxW={isFullWidth(fullWidth)}
          maxW="1366px"
          width="100%"
          mx="auto"
          // my={12}
          pt={{ base: 2, lg: layoutSpacing(innerSpacing?.topPadding) }}
          pr={{ base: 2, lg: layoutSpacing(innerSpacing?.leftPadding) }}
          pb={{ base: 2, lg: layoutSpacing(innerSpacing?.bottomPadding) }}
          pl={{ base: 2, lg: layoutSpacing(innerSpacing?.leftPadding) }}
          mt={fullWidth ? 0 : layoutSpacing(outerSpacing?.topMargin)}
          mb={fullWidth ? 0 : layoutSpacing(outerSpacing?.bottomMargin)}


          backgroundImage={(!layoutBleed === true && !fullWidth === true && layoutBgImage) && layoutBgImage(bgImage)}
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"

          // marginLeft={fullWidth && `calc(50% - 50vw)`}
          // backgroundColor={!layoutBleed && bgColor(colorTheme)}
          // backgroundImage={layoutBgImage(bgImage)}
          // backgroundSize="cover"
          // backgroundPosition="center"
          // backgroundRepeat="no-repeat"
          color={fontColor(colorTheme)}
          position="relative"
          zIndex={0}

          // _before={
          //   layoutBleed && {
          //     content: '""',
          //     position: "absolute",
          //     backgroundColor: "rgba(17, 17, 17, 1)",
          //     top: 0,
          //     left: "calc(100% + 0px)",
          //     width: "100%",
          //     height: "100%",
          //     zIndex: -1,
          //   }
          // }
          // _after={
          //   layoutBleed && {
          //     content: '""',
          //     position: "absolute",
          //     backgroundColor: "rgba(17, 17, 17, 1)",
          //     top: 0,
          //     right: "calc(100% + 0px)",
          //     width: "100%",
          //     height: "100%",
          //     zIndex: -1,
          //   }
          // }
        >
          {layout?.flexibleLayoutColOneContent?.length > 0 && (
            <Box
              zIndex="1"
              height="100%"
              mx="auto"
              maxW="1366px"
              w="100%"
              // px={{ base: "4", md: "6", xl: "8", "2xl": "0" }}
            >
              <FlexibleContent
                content={layout?.flexibleLayoutColOneContent}
                theme={colorTheme}
              />
            </Box>
          )}
          {layout?.flexibleLayoutColTwoContent?.length > 0 && (
            <Box
              zIndex="1"
              //height='100%' mx='auto' maxW='1366px' w='100%' px={{ base: '4', md: '6', 'xl': '8', '2xl': '0' }}
            >



              <FlexibleContent
                content={layout?.flexibleLayoutColTwoContent}
                theme={colorTheme}
              />
            </Box>
          )}
          {layout?.flexibleLayoutColThreeContent?.length > 0 && (
            <Box
              zIndex="1"
              //height='100%' mx='auto' maxW='1366px' w='100%' px={{ base: '4', md: '6', 'xl': '8', '2xl': '0' }}
            >
              <FlexibleContent
                content={layout?.flexibleLayoutColThreeContent}
                theme={colorTheme}
              />
            </Box>
          )}
          {layout?.flexibleLayoutColFoursContent?.length > 0 && (
            <Box
              zIndex="1"
              //height='100%' mx='auto' maxW='1366px' w='100%' px={{ base: '4', md: '6', 'xl': '8', '2xl': '0' }}
            >
              <FlexibleContent
                content={layout?.flexibleLayoutColFoursContent}
                theme={colorTheme}
              />
            </Box>
          )}
        </SimpleGrid>
        <BottomBorder bottomBorder={bottomBorder} />
      </Box>
    );
  };

  return (
    <>
      {layout.map((layout) => (
        <LayoutManager key={layout._key} layout={layout} />
      ))}
    </>
  );
}
