import React from 'react'
import { chakra } from '@chakra-ui/react'
import { motion } from 'framer-motion'

export const MotionDiv = motion(chakra.div)
const variants = {
  init: {
    opacity: 0,
    y: -4,
    display: 'none',
    transition: {
      duration: 0
    }
  },
  open: {
    opacity: 1,
    y: 0,
    display: 'block',
    transition: {
      duration: 0.15
    }
  },
  closed: {
    opacity: 0,
    y: -4,
    transition: {
      duration: 0.1
    },
    transitionEnd: {
      display: 'none'
    }
  }
}
export const NavMenu = React.forwardRef((props, ref) => (
  <MotionDiv
    ref={ref}
    initial='init'
    variants={variants}
    outline='0'
    opacity='0'
    bg='#f6f6f6'
    w='full'
    shadow='lg'
    px='2rem'
    pos='absolute'
    insetX='0'
    // mt='1rem'
    pt='4'
    pb='6'

    {...props}
  />
))
NavMenu.displayName = 'NavMenu'
