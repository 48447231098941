import React from 'react'
import getVideoId from 'get-video-id'
// import {LiteYoutubeEmbed} from 'react-lite-yt-embed'

function Youtube (props) {
  // console.log({ props })
  if (!props || !props.url) {
    return <div>Missing YouTube URL</div>
  }
  const { id } = getVideoId(props.url)

  if (!id) {
    return <div>Missing YouTube URL</div>
  }
  return (
    <div className='videoWrapper' style={{ position: 'relative', paddingBottom: '56.25%', height: '0' }}>
      <iframe title="YouTub Video" style={{ position: 'absolute', top: '0', left: '0', height: '100%', width: '100%' }} src={`https://www.youtube.com/embed/${id}?rel=0`} frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowFullScreen />
    </div>
  )
}
export default Youtube