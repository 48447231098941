import React from "react";
import { Box } from "@chakra-ui/react";
import { NavContent } from "./nav-content";
// import background from "../../../images/PremierFlexStripe.png"
// backgroundImage={`linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)), url(${background})`} bgRepeat="repeat-x" bgPosition="bottom center
export const MainHeader = () => {
  return (
    <Box as="header" bg="#f6f6f6" position="relative" zIndex="10" shadow="2xl">
      <Box
        as="nav"
        aria-label="Main navigation"
        mx="auto"
        maxW="1366px"
        w="100%"
        px={{ base: "1rem", md: "2rem", "2xl": "0" }}
        pt={{ base: "1", md: "2", "2xl": "4"}}
        pb={{ base: "3", md: "4", "2xl": "6"}}
      >
        <NavContent.Mobile
          display={{
            base: "flex",
            lg: "none",
          }}
        />
        <NavContent.Desktop
          display={{
            base: "none",
            lg: "flex",
          }}
        />
      </Box>
    </Box>
  );
};
