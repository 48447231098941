import React from "react";
import Helmet from "react-helmet";
import SchemaOrg from './schema-org'
import { useSiteSettings } from "../../hooks/use-site-settings";


export const Seo = ({ description, lang, meta, title, openGraphImage, isBlogPost, datePublished}) => {
  const site = useSiteSettings();

  const metaDescription = description || site.seoSettings.description || "";
  const ogImage = () => {
    if (openGraphImage !== null) {
      return openGraphImage;
    } else if (site?.seoSettings?.openGraphImage?.asset?.url) {
      return site?.seoSettings?.openGraphImage?.asset?.url;
    } else {
      return null;
    }
  };
  const metaTitle = title || site.seoSettings.title || "";
  const pageURL = typeof window !== "undefined" ? window.location.href : "";
  // console.log(
  //   `pageURL: ${pageURL}, metaTitle: ${metaTitle}, metaDescription: ${metaDescription}, `
  // );
  return (
    <>
      <Helmet>
        {/* General tags */}
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="image" content={ogImage()} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={pageURL} />
        {isBlogPost ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={ogImage()} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={ogImage()} />
      </Helmet>
      <SchemaOrg
        isBlogPost={isBlogPost}
        url={pageURL}
        title={metaTitle}
        image={ogImage()}
        description={metaDescription}
        canonicalUrl={pageURL}
        organization={metaTitle}
        defaultTitle={metaTitle}
      />
    </>
  );
}
