import React from 'react'
import { Flex } from '@chakra-ui/react'
import { FlexibleLayout } from '../Serializers/FlexibleLayout'
import { MainHeader } from './header'
import { Footer } from './footer'

export function Layout(props) {

  const {layout, children} = props
  // console.log({title})
  // console.log({layout})
  return (
    <Flex flexDir='column' flexWrap='wrap' width='100%' minH='100vh'>
      <MainHeader />
      <Flex as='main' flexDir='column' width='100%' height='100%' flex='1' overflow='hidden'>
      <Flex
      flexDir='column'
      position='relative'
      //flexDir='column' mx='auto' maxW='1366px' w='100%' px={{ base: '4', md: '6', 'xl': '8', '2xl': '0' }}
      >
      {layout && <FlexibleLayout layout={layout} />}
      {children}
      </Flex>
    </Flex>
      <Footer />
    </Flex>
  )
}
