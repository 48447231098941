import React from 'react'
import { Webform } from './Webform'

export function ContentViews ({ content, webform }) {
  // console.log(`content: ${JSON.stringify(content, 0, 2)}`)
  if (content?.contentView === 'wfContact') {
    return (
      <Webform key={content._key} />
    )
  } else {
    return <div></div>
  }
}
