import React from 'react'

import './figure.module.css'

export default function Iframe (props) {
  // let url = props.url
  // let height = props.height
  return <>iframe</>
  // return (
  //   <>
  //     <iframe
  //       src={url}
  //       height={height}
  //       width='100%'
  //       frameBorder='0'
  //     />
  //   </>
  // )
}