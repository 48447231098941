import React from 'react'
import { Box, Center, chakra, HStack, Text } from '@chakra-ui/react'
import { FaChevronRight } from 'react-icons/fa'

export const SubmenuItem = (props) => {
  const { title, icon, children, href, ...rest } = props
  return (
    <chakra.a
      className='group'
      href={href}
      m='-3'
      p='3'
      display='flex'
      alignItems='center'
      transition='all 0.2s'
      // rounded='lg'
      _hover={{
        // bg: mode('gray.50', 'gray.200')
      }}
      _focus={{
        shadow: 'outline'
      }}
      {...rest}
    >
      <Center
        aria-hidden
        as='span'
        flexShrink={0}
        w='10'
        h='10'
        fontSize='3xl'
        color='white'
      >
        {icon}
      </Center>
      <Box marginStart='3' as='dl'>
        <HStack as='dt'>
          <Text
            // fontWeight='semibold'
            color='#111'
            margin='0'
            _groupHover={{
              color: '#1D5487'
            }}
          >
            {title}
          </Text>
          <Box
            fontSize='xs'
            as={FaChevronRight}
            transition='all 0.2s'
            color='#111'
            _groupHover={{
              color: '#1D5487',
              transform: 'translateX(2px)'
            }}
          />
        </HStack>
        <Text as='dd' color={('gray.200')} p='0'>
          {children}
        </Text>
      </Box>
    </chakra.a>
  )
}
