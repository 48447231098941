import React from 'react'
import clientConfig from "../../../client-config";
// import BaseBlockContent from "@sanity/block-content-to-react";
import BasePortableText from "@sanity/block-content-to-react";
import ContentSerializer from "./ContentSerializer";

export function Text({ blocks }) {
  // console.log({blocks})
  if(!blocks){
    return null
  }
  return (
    <BasePortableText blocks={blocks} serializers={ContentSerializer} {...clientConfig.sanity} />
  )
}