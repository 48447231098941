import React from 'react'
import { Link } from 'gatsby'
import { useMainNav } from "../../../hooks/use-site-nav";
import { Logo } from './logo'

import { NavLink } from './nav-link'
import { NavMenu } from './nav-menu'
import { Submenu } from './sub-menu'
import { ToggleButton } from './toggle-button'
import {
  Box,
  Flex,
  HStack,
  useDisclosure
} from '@chakra-ui/react'

const MobileNavContext = (props) => {
  const mainNav = useMainNav();
  const { isOpen, onToggle } = useDisclosure()
  return (
    <>
      <Flex align='center' justify='space-between' {...props}>
        <Link to='/' title='Home' aria-label='Home'><Logo w='220px' /></Link>
        <Box>
          <ToggleButton isOpen={isOpen} onClick={onToggle} />
        </Box>
      </Flex>
      <NavMenu animate={isOpen ? 'open' : 'closed'} >
        {mainNav?.links.map((link, idx) =>
          link?.links.length > 0 ? (
            <Submenu.Mobile key={idx} link={link} />
          ) : (
            <NavLink.Mobile key={idx} href={link.siteLink}>
              {link.title}
            </NavLink.Mobile>
          )
        )}
      </NavMenu>
    </>
  )
}

const DesktopNavContent = (props) => {
  const mainNav = useMainNav();
  return (
    <Flex className='nav-content__desktop' align='center' justify='space-between' {...props}>
      <Link to='/' title='Home' aria-label='Home'><Logo w='300px' /></Link>
      <HStack as='ul' id='nav__primary-menu' aria-label='Main Menu' listStyleType='none'>
        {mainNav?.links.map((link, idx) => (
          <Box as='li' key={idx} id={`nav__menuitem-${idx}`}>
            {link?.links.length > 0 ? (
              <Submenu.Desktop link={link} />
            ) : (
              <NavLink.Desktop href={link.siteLink}>{link.title}</NavLink.Desktop>
            )}
          </Box>
        ))}
      </HStack>
    </Flex>
  )
}

export const NavContent = {
  Mobile: MobileNavContext,
  Desktop: DesktopNavContent
}
