import React from 'react'
import { SRLWrapper } from 'simple-react-lightbox'

import { Flex, Img, Box } from '@chakra-ui/react'
import imageUrlBuilder from '@sanity/image-url'
const config = {
  dataset: process.env.GATSBY_SANITY_DATASET,
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  useCdn: process.env.NODE_ENV === 'production',
  token: process.env.SANITY_READ_TOKEN
}
const builder = imageUrlBuilder(config)

export function ImageComponent ({ content: { lightbox, link, blank, mainImage, layoutStyle, imageWidth, imageHeight, imageStyle } }) {

  const options = {
    settings: {
      autoplaySpeed: 3000,
      boxShadow: 'none',
      disableKeyboardControls: false,
      disablePanzoom: true,
      disableWheelControls: false,
      hideControlsAfter: true,
      lightboxTransitionSpeed: 0.3,
      lightboxTransitionTimingFunction: 'linear',
      overlayColor: 'rgba(30, 30, 30, 0.9)',
      slideAnimationType: 'fade',
      slideSpringValues: [300, 50],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: 'linear',
      usingPreact: false
    },
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false
    },
    thumbnails: {
      showThumbnails: false
    }
  }

  const ImgWidth = imageWidth || '640'
  const ImgHeight = imageHeight || '480'

  const ImageLink = ({ children, link, blank, lightbox, image, alt }) => {
    // console.log({ blank })
    if (lightbox) {
      return <Lightbox lightbox={lightbox} image={image} alt={alt}>{ children }</Lightbox>
    }
    if (!link) {
      return <>{ children }</>
    }
    if (link.includes('https') || link.includes('http') || link.includes('mailto') || link.includes('tel')) {
      return <a href={link} target={blank === true ? '_blank' : '_self'} rel='noopener noreferrer'>{ children }</a>
    }
    if (link) {
      return <a href={link} target={blank === true ? '_blank' : '_self'} rel='noreferrer'>{ children }</a>
    }
  }
  const Lightbox = ({ children, image }) => {
    return (
      <SRLWrapper options={options}>
        <a href={builder
          .image(image)
          .width(1400)
          .auto('format')
          .fit('max')
          .url()} width="100%">
          { children }
        </a>
      </SRLWrapper>
    )
  }

  return (
    <Flex alignItems='center' justifyContent='center' >
      <ImageLink link={link} blank={blank} lightbox={lightbox} image={mainImage} alt={mainImage?.alt}>

      {imageStyle === true ? (
        <Box background="linear-gradient(rgba(29, 38, 113, 0.8), rgba(195, 55, 100, 0.8))" overflow='hidden'>
        <Img
          src={builder
            .image(mainImage)
            .auto('format')
            .width(ImgWidth || '640')
           .height(ImgHeight || '480')
            .fit('scale')
            .url()}
            alt={mainImage?.alt}
            mixblendmode="overlay"
            opacity=".3"
            _hover={{ mixBlendMode: 'normal', transition:'all 400ms ease-out', opacity: '1', transform: 'scale(1.1)' }}
            transition='all 200ms ease-out'

        />
       </Box>
      ):(

        <Img
          src={builder
            .image(mainImage)
            .auto('format')
            .width(ImgWidth || '640')
           .height(ImgHeight || '480')
            .fit('scale')
            .url()}
            alt={mainImage?.alt}
        />
      )}

      </ImageLink>
    </Flex>
  )
}
