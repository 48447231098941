import { useStaticQuery, graphql } from "gatsby"

export const useMainNav = () => {
  const {mainNav} = useStaticQuery(
    graphql`
      query sanityNav {
        mainNav: sanityNavigation
        (_id: {regex: "/(drafts.|)mainNav/"}) {
          links {
            _key
            title
            siteLink
            links {
              _key
              title
              siteLink
            }
        }
      }
    }
    `
  )
  return mainNav
}