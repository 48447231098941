import React from 'react'
import { Box, Center, chakra, VisuallyHidden } from '@chakra-ui/react'
const Bar = chakra('span', {
  baseStyle: {
    display: 'block',
    pos: 'absolute',
    w: '1.25rem',
    h: '0.125rem',
    // rounded: 'full',
    bg: '#111',
    mx: 'auto',
    insetStart: '0.125rem',
    transition: 'all 0.12s'
  }
})

const ToggleIcon = (props) => {
  const { active } = props
  return (
    <Box
      className='group'
      data-active={active ? '' : undefined}
      as='span'
      display='block'
      w='1.5rem'
      h='1.5rem'
      pos='relative'
      aria-hidden
      pointerEvents='none'
    >
      <Bar
        bottom='18px'
        _groupActive={{
          top: '0.6875rem',
          transform: 'rotate(45deg)'
        }}
      />

      <Bar
        bottom='10px'
        _groupActive={{
          bottom: '0.6875rem',
          transform: 'rotate(-45deg)'
        }}
      />

      <Bar
        bottom='2px'
        _groupActive={{
          bottom: '0.6875rem',
          transform: 'rotate(-45deg)'
        }}
      />
    </Box>
  )
}

export const ToggleButton = (props) => {
  const { isOpen, onClick } = props
  return (
    <Center
      px='4'
      py='4'
      as='button'
      color='#111'
      _active={{
        color: '#1D5487'
      }}
      onClick={onClick}
    >
      <ToggleIcon active={isOpen} />
      <VisuallyHidden>Toggle Menu</VisuallyHidden>
    </Center>
  )
}
