import { useStaticQuery, graphql } from "gatsby"

export const useSiteSettings = () => {
  const {site} = useStaticQuery(
    graphql`
      query SiteMetaData {
        site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
        title
        _updatedAt
        seoSettings {
          title
          description
          openGraphImage {
            asset {
              url
            }
          }
        }
      }
    }
    `
  )
  return site
}