/*
    Feature - schema:
    Type: _type - example: 'flexibleCTA'
    Style: style - example: 'centerAligned', 'rightAligned', 'centerAligned'
    Key: _key

    Theme: theme

    Prefix: prefix
    Title: title
    Sub Title: subTitle
    Text: text

    Primary Link Style: primaryLink.style
    Primary Title Style: primaryLink.title
    Primary URL Style: primaryLink.url
    Primary Open in new tab: primaryLink.newTab

    Secondary Link Style: secondaryLink.style
    Secondary Title Style: secondaryLink.title
    Secondary URL Style: secondaryLink.url
    Secondary Open in new tab: secondaryLink.newTab

*/

// import { isEmpty } from '@/utils/helpers'
import React from "react";
import { Flex, Box, Button, Heading, Text } from "@chakra-ui/react";

const linkColor = (theme) => {
  if (theme === "alternate") {
    return "#fff";
  }
  if (theme === "dark") {
    return "#fff";
  } else {
    return "none";
  }
};
const btnColor = (theme) => {
  if (theme === "alternate") {
    return "#fff";
  }
  if (theme === "dark") {
    return "#fff";
  } else {
    return "none";
  }
};
const btnBG = (theme) => {
  if (theme === "alternate") {
    return "#929497";
  }
  if (theme === "dark") {
    return "#111";
  } else {
    return "none";
  }
};
const secondaryColor = (theme) => {
  if (theme === "alternate") {
    return "#F0F0F0";
  }
  if (theme === "dark") {
    return "#fff";
  } else {
    return "gray.600";
  }
};
// const maxWLG = (style) => {
//   if (!style) {
//     return "100%";
//   } else if (style === "centerAligned") {
//     return "640px";
//   } else {
//     return "50%";
//   }
// };
// const maxWMD = (style) => {
//   if (!style) {
//     return "100%";
//   } else if (style === "centerAligned") {
//     return "640px";
//   } else {
//     return "50%";
//   }
// };
const LinkBuilder = ({ link, title, style, primary, theme }) => {
  // style = buttonStyle or textStyle
  // link = external or internal

  if (style === "textStyle") {
    if (
      link.includes("https") ||
      link.includes("http") ||
      link.includes("mailto") ||
      link.includes("tel")
    ) {
      return (
        <Button
          as="a"
          href={link}
          fontWeight="bold"
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
          _hover={{ cursor: "pointer" }}
          target="_blank"
          rel="noopener noreferrer"
          mr={{
            base: "0",
            md: `${primary ? "1" : "0"}`,
            lg: `${primary ? "2" : "0"}`,
          }}
          ml={{
            base: "0",
            md: `${primary ? "0" : "1"}`,
            lg: `${primary ? "0" : "2"}`,
          }}
          mb={{ base: "0", md: "2", lg: "3" }}
          variant="link"
          color={linkColor(theme)}
        >
          {title}
        </Button>
      );
    } else {
      return (
        <Button
          as="a"
          fontWeight="bold"
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
          _hover={{ cursor: "pointer" }}
          mr={{
            base: "0",
            md: `${primary ? "1" : "0"}`,
            lg: `${primary ? "2" : "0"}`,
          }}
          ml={{
            base: "0",
            md: `${primary ? "0" : "1"}`,
            lg: `${primary ? "0" : "2"}`,
          }}
          mb={{ base: "0", md: "2", lg: "3" }}
          variant="link"
          color={linkColor(theme)}
          href={link}
        >
          {title}
        </Button>
      );
    }
  } else {
    if (
      link.includes("https") ||
      link.includes("http") ||
      link.includes("mailto") ||
      link.includes("tel")
    ) {
      return (
        <Button
          as="a"
          href={link}
          px={{ base: "5", md: "6", lg: "7" }}
          py={{ base: "6", md: "6", lg: "6" }}
          fontWeight="bold"
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
          _hover={{ cursor: "pointer" }}
          target="_blank"
          rel="noopener noreferrer"
          variant="solid"
          // width={{ base: "100%", md: "auto" }}
          mr={{
            base: "0",
            md: `${primary ? "1" : "0"}`,
            lg: `${primary ? "2" : "0"}`,
          }}
          ml={{
            base: "0",
            md: `${primary ? "0" : "1"}`,
            lg: `${primary ? "0" : "2"}`,
          }}
          mb={{ base: "0", md: "2", lg: "3" }}
          color={btnColor(theme)}
          bgColor={btnBG(theme)}
        >
          {title}
        </Button>
      );
    } else {
      return (

          <Button
            as="a"
            px={{ base: "5", md: "6", lg: "7" }}
            py={{ base: "6", md: "6", lg: "6" }}
            fontWeight="bold"
            fontSize={{ base: "sm", md: "md", lg: "lg" }}
            _hover={{ cursor: "pointer" }}
            variant="solid"
            // width={{ base: "100%", md: "auto" }}
            mr={{
              base: "0",
              md: `${primary ? "1" : "0"}`,
              lg: `${primary ? "2" : "0"}`,
            }}
            ml={{
              base: "0",
              md: `${primary ? "0" : "1"}`,
              lg: `${primary ? "0" : "2"}`,
            }}
            mb={{ base: "0", md: "2", lg: "3" }}
            // color={btnColor(theme)}
            color="#111"
            // bgColor={btnBG(theme)}
            bgColor="#81c90a"
            href={link}
          >
            {title}
          </Button>

      );
    }
  }
};

const alignItems = (align) => {
  if (!align) {
    return "";
  } else if (align === "centerAligned") {
    return "center";
  } else if (align === "leftAligned") {
    return "flex-start";
  } else if (align === "rightAligned") {
    return "flex-end";
  } else {
    return "flex-start";
  }
};

export function FlexibleCTA({ content, theme }) {
  const {
    prefix,
    title,
    subTitle,
    text,
    primaryLink,
    secondaryLink,
    style,
  } = content;
  return (
    <Box
      as="section"
      mt={{ base: "3", md: "4", lg: "5", xl: "4" }}
      mb={{ base: "2", md: "3", lg: "4", xl: "4" }}
      position="relative"
      p={{ base: "0" }}
      //height='100%'
      // minH={{ base: 'auto', lg: 'auto' }}
    >
      <Flex
        // order={`${style === "rightAligned" ? "3" : "1"}`}
        flexDir={{ base: "column", lg: "column" }}
        // justify="space-between"
        // maxW={{ base: "100%", md: maxWMD(style), lg: maxWLG(style) }}
        alignItems={{
          base: "flex-start",
          md: alignItems(style),
          lg: alignItems(style),
        }}
        width="100%"
      >
        <Box>
          {prefix && (
            <Text
              fontWeight="semibold"
              fontSize={{ base: "xs", md: "xs", lg: "sm" }}
              mt={0}
              mb={0}
              color={secondaryColor(theme)}
            >
              {prefix}
            </Text>
          )}

          {title && (
            <Heading
              as="h2"
              fontSize={{ base: "4xl", md: "5xl", lg: "6xl" }}
              letterSpacing="tight"
              lineHeight="1"
              color="#FFF31B"
              textTransform="none"
              fontWeight="600"
              _before={{display: 'none'}}
              mt={0}
              mb={1}
            >
              {title}
            </Heading>
          )}

          {subTitle && (
            <Heading
              as="h3"
              fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
              fontWeight="bold"
              letterSpacing="tight"
              lineHeight="1"
              textTransform="none"
              _before={{display: 'none'}}
              mt={0}
              mb={2}
            >
              {subTitle}
            </Heading>
          )}

          {text && (
            <Text
              fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
              mx={0}
              color={secondaryColor(theme)}
            >
              {text}
            </Text>
          )}
          {/* BUTTONS */}
          {(primaryLink?.url || secondaryLink?.url) && (
            <Flex
              direction={{ base: "column", md: "row", lg: "row" }}
              mt="6"
              maxW="md"
              alignItems={{
                base: "flex-start",
                md: alignItems(style),
                lg: alignItems(style),
              }}
              flexWrap="wrap"
              justify="flex-start"
            >
              {primaryLink?.url && (
                <LinkBuilder
                  link={primaryLink.url}
                  title={primaryLink.title}
                  style={primaryLink.style}
                  primary
                  theme={theme}
                />
              )}
              {primaryLink?.url && secondaryLink?.url && (
                <Box
                  aria-hidden
                  className="spacer"
                  flexShrink={0}
                  boxSize={{
                    base: `${style !== "centerAligned" ? "4" : "0"}`,
                    md: `${style !== "centerAligned" ? "4" : "0"}`,
                    lg: `${style !== "centerAligned" ? "6" : "0"}`,
                  }}
                />
              )}
              {secondaryLink?.url && (
                <LinkBuilder
                  link={secondaryLink.url}
                  title={secondaryLink.title}
                  style={secondaryLink.style}
                  theme={theme}
                />
              )}
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
}
