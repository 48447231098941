import { useStaticQuery, graphql } from "gatsby"

export const useContactWebform = () => {
  const {site} = useStaticQuery(
    graphql`
      query ContactWebform {
        site: sanityWebform(_id: {regex: "/(drafts.|)webform/"}) {
          id
          title
          formSettings {
            thankyou
            emailto
            emailfrom
            subject
        }
      }
    }
    `
  )
  return site
}