/*
    Featurette - schema:
    Style: style - example: 'leftStyle'
    Title: title
    Text: text
    link: url
    Icon: icon
*/
import React from 'react'
import { Link } from 'gatsby'

import { Box, Stack, Text } from '@chakra-ui/react'

const Feature = (props) => {
  const { title, children, link, style } = props
  const styleLayout = (style) => {
    return style === 'topStyle' ? 'column' : 'row'
  }
  const LinkFilter = ({link, title}) => {
    if(link.includes('https') || link.includes('http') ) {
      return (
      <a href={link} target='_blank' rel='noopener noreferrer'>
        {title}
      </a>
      )
    } else {
      return <Link href={link}>{title}</Link>
    }
  }
  return (
    <Stack spacing={{ base: `${style === 'topStyle' ? '1' : '1'}`, md: `${style === 'topStyle' ? '1' : '3'}`, lg: `${style === 'topStyle' ? '1' : '4'}` }} direction={styleLayout(style)} mb={{ base: 4, md: 6, lg: 8}}>

      <Box >
        {title && <Text fontWeight='extrabold' fontSize={{ base: 'xl', md: 'xl', lg: '3xl', xl: '3xl' }}>
          {link ? (
            <LinkFilter link={link} title={title} />
          ) : (
            <>{title}</>
          )}
        </Text>}
        {children && <Box color={('gray.600')} fontSize={{ base: 'sm', md: 'md' }}>{children}</Box>}
      </Box>
    </Stack>
  )
}

export function FlexibleFeature ({ content }) {
  // console.log({ content })
  const { title, text, url, style } = content
  return (
    <Feature title={title} link={url} style={style}>
      {text}
    </Feature>
  )
}
