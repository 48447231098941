import React from 'react'
// import Slideshow from './Slideshow'
// import Gallery from './Gallery'
import { Text } from './Text'
import { ImageComponent } from './ImageComponent'
import Youtube from './Youtube'
import Vimeo from './Vimeo'
import { ContentViews } from './ContentViews'
import { FlexibleFeature } from './FlexibleContent/FlexibleFeature'
import { FlexibleCTA } from './FlexibleContent/FlexibleCTA'

export function FlexibleContent ({ content, webform, theme }) {

  const ContentManager = ({ content, theme }) => {
    // console.log(`content: ${JSON.stringify(content, 0, 2)}, theme: ${theme}`)

    if (content?._type === 'textBlock') {
      return <Text blocks={content.body} />
    }
    if (content?._type === 'flexibleImageComponent') {
      return <ImageComponent content={content} key={content._key} theme={theme} />
    }
    if (content?._type === 'youtube') {
      return <Youtube {...content} key={content._key} />
    }
    if (content?._type === 'vimeo') {
      return <Vimeo {...content} key={content._key} />
    }
    // if (content?._type === 'slideshow') {
    //   return <Slideshow {...content} key={content._key} />
    // }
    // if (content?._type === 'imageGallery') {
    //   return <Gallery {...content} key={content._key} />
    // }

    if (content?._type === 'flexibleView') {
      return <ContentViews content={content} key={content._key} webform={webform} theme={theme} />
    }
    if (content?._type === 'flexibleFeaturette') {
      return <FlexibleFeature content={content} key={content._key} theme={theme} />
    }
    if (content?._type === 'flexibleCTA') {
      return <FlexibleCTA content={content} key={content._key} theme={theme} />
    } else {
      return null
    }
  }
  return (
    <>
      {content.map((content) => (
        <ContentManager key={content._key} content={content} theme={theme} />
      ))}
    </>
  )
}
