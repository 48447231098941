import React from 'react'
import { useNavMenu } from './use-nav-menu'
import { Box, Collapse, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { FaChevronDown } from 'react-icons/fa'
import { NavLink } from './nav-link'
import { NavMenu } from './nav-menu'
import { SubmenuItem as DesktopMenuItem } from './submenu-item'

const DesktopSubmenu = (props) => {
  // console.log({props})
  const { link } = props
  const { isOpen, getMenuProps, getTriggerProps } = useNavMenu()
  return (
    <>
      <NavLink.Desktop
        display='flex'
        alignItems='center'
        as='button'
        type='button'
        px='4'
        // fontWeight='semibold'
        {...getTriggerProps()}
      >
        <Box>{link.title}</Box>
        <Box marginStart='2' as={FaChevronDown} fontSize='xs' />
      </NavLink.Desktop>

      <NavMenu {...getMenuProps()} animate={isOpen ? 'open' : 'closed'}>
        <Box maxW='7xl' mx='auto' px='8'>
          <SimpleGrid spacing='10' columns={2}>
            {link.links?.map((item, idx) => (
              <DesktopMenuItem key={idx} href={item.siteLink} title={item.title} icon={item.icon} />
            ))}
          </SimpleGrid>
        </Box>
      </NavMenu>
    </>
  )
}

const MobileSubMenu = (props) => {
  const { link } = props
  const { isOpen, onToggle } = useDisclosure()
  return (
    <Box>
      <NavLink.Mobile
        as='button'
        textAlign='start'
        type='button'
        cursor='pointer'
        onClick={onToggle}
        paddingEnd='4'
      >
        <Box flex='1'>{link.title}</Box>
        <Box as={FaChevronDown} transform={`rotate(${isOpen ? '180deg' : '0deg'})`} />
      </NavLink.Mobile>
      <Collapse in={isOpen}>
        <Box pl='5'>
          {link.links?.map((item, idx) => (
            <NavLink.Mobile key={idx} href={item.siteLink}>
              {item.title}
            </NavLink.Mobile>
          ))}
        </Box>
      </Collapse>
    </Box>
  )
}

export const Submenu = {
  Mobile: MobileSubMenu,
  Desktop: DesktopSubmenu
}
