import React from 'react'
import { chakra } from '@chakra-ui/react'
const DesktopNavLink = React.forwardRef((props, ref) => {
  const { active, ...rest } = props
  return (
    <chakra.a
      ref={ref}
      display='inline-block'
      pl='4'
      py='2.5'
      fontSize='1.2rem'
      aria-current={active ? 'page' : undefined}
      color='#0E0F12'
      transition='all 0.2s'
      {...rest}
      _hover={{
        color: '#1D5487'
      }}
      _active={{
        color: '#1D5487'
      }}
      _activeLink={{
        color: '#1D5487',
        fontWeight: 'bold'
      }}

    />
  )
})
DesktopNavLink.displayName = 'DesktopNavLink'

export const MobileNavLink = (props) => {
  const { active, ...rest } = props
  return (
    <chakra.a
      aria-current={active ? 'page' : undefined}
      w='full'
      display='flex'
      alignItems='center'
      height='14'
      // fontWeight='semibold'
      borderBottomWidth='1px'
      color='#0E0F12'
      {...rest}
    />
  )
}
export const NavLink = {
  Mobile: MobileNavLink,
  Desktop: DesktopNavLink
}
