import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./slideshow.css";

import imageUrlBuilder from "@sanity/image-url";
const config = {
  dataset: process.env.GATSBY_SANITY_DATASET,
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  useCdn: process.env.NODE_ENV === "production",
  token: process.env.SANITY_READ_TOKEN,
};
const builder = imageUrlBuilder(config);

function Slideshow(props) {
  const { slides } = props;
  return (
    <div style={{ maxWidth: "960px", margin: "0 auto" }}>
      <Carousel showStatus={false} dynamicHeight swipeable showThumbs={false}>
        {slides.map((image) => (
          <img
            key={image?._key}
            src={builder
              .image(image.slideImage)
              .width(960)
              .auto("format")
              .fit("max")
              .url()}
            alt={image?.title}
          />
        ))}
      </Carousel>
    </div>
  );
}
export default Slideshow;
