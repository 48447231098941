// TODO: Add the webform functionality
import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  VStack,
  Spinner,
} from "@chakra-ui/react";

import { navigate } from "gatsby-link";
import Helmet from "react-helmet";
import Recaptcha from "react-recaptcha";
// import BasePortableText from "@sanity/block-content-to-react";

import { useContactWebform } from "../../../hooks/use-contact-webform";

// import {styles} from './page.module.css'

export function Webform(props) {
  // console.log({ props });
  const contactWebform = useContactWebform();
  const { formSettings } = contactWebform;
  // console.log({ formSettings });

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  // State
  // const [formValues, setFormValues] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState("")
  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value })
  }

  const [isVerified, setIsVerified] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);

  // Google Recaptcha
  let recaptchaLoaded = function () {
    // console.log("Recaptcha Loaded");
  };
  let expiredCallback = function () {
    // console.log("expired");
    setIsVerified(false);
    setRecaptchaResponse(null);
  };
  let verifyCallback = function (response) {
    // console.log(response);
    if (response) {
      setIsVerified(true);
      setRecaptchaResponse(response);
    } else {
      setIsVerified(false);
      setRecaptchaResponse(null);
    }
  };

  // const handleChange = (e) => {
  //   setFormValues({ ...formValues, [e.target.name]: e.target.value });
  // };


  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log({ e });
    setIsLoading(true)
    if (
      isVerified &&
      value.first_name &&
      value.last_name &&
      value.email &&
      value.message
    ) {
      // console.log("valid form");
      // console.log({ value });
      setFormErrors(false);
      const form = e.target;
      fetch("/.netlify/functions/email", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          to: formSettings.emailto,
          from: formSettings.emailfrom,
          subject: formSettings.subject,
          ...value,
          recaptcha: recaptchaResponse,
        }),
      })
        .then(() => {
          navigate(form.getAttribute("action"))
          }
        )
        .catch((error) => {
          setIsLoading(false)
          console.error(error)
        });
    } else {
      setIsLoading(false);
      // console.log("invalid form");
      setFormErrors(true);
    }
    setIsLoading(false)
  };

  return (
    <>
      <Helmet>
        <script
          key={`recaptcha`}
          src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
          async
          defer
        />
      </Helmet>

      {/* content && <BasePortableText blocks={content} /> */}
      {isLoading ? (
        <Spinner />
      ) : (
        <form
        name={formSettings.subject}
        method="post"
        action={formSettings.thankyou}
        onSubmit={handleSubmit}
      >
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name={"bot-field"} />
          </label>
        </div>

        <div>
          <VStack width="full" spacing="6">

            <Stack direction={{base: "column", md: "row"}} spacing={{base: "6", md: "12"}} width="100%">

              <FormControl id="first_name">
                <FormLabel>First Name</FormLabel>
                <Input type="text" maxLength={255} onChange={handleChange} name='first_name'/>
              </FormControl>

              <FormControl id="last_name">
                <FormLabel>Last Name</FormLabel>
                <Input type="text" maxLength={255} onChange={handleChange} name='last_name'/>
              </FormControl>

            </Stack>

            <Stack direction={{base: "column", md: "row"}} spacing={{base: "6", md: "12"}} width="100%">

              <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input type="email" onChange={handleChange} name='email'/>
              </FormControl>

              <FormControl id="phone">
              <FormLabel>Phone</FormLabel>
              <Input type="phone" onChange={handleChange} name='phone'/>
              </FormControl>

            </Stack>

            <FormControl id="message">
              <FormLabel>Message</FormLabel>
              <Textarea rows={3} required  onChange={handleChange} name='message'/>
            </FormControl>
          </VStack>
        </div>

        <Box mt="8">
          <Recaptcha
            sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_PUBLIC}
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={recaptchaLoaded}
            expiredCallback={expiredCallback}
          />
        </Box>

        <Box mt="6">
        <Button type="submit" colorScheme="blue"
        isDisabled={
          isVerified &&
          value.first_name &&
          value.last_name &&
          value.email &&
          value.message
            ? false
            : true
        }
        >
            Submit
          </Button>


        </Box>


        {formErrors && (
          <div>
            <p>
              Unable to submit form. Please make sure all of your fields are
              filled out.
            </p>
          </div>
        )}

      </form>
      )}

    </>
  );
}
